import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { FaCopy, FaDownload } from 'react-icons/fa'; // Importing the icons
import { UniquePaymentContainer, UniquePaymentHeader, UniqueQRWrapper, IconContainer, Icon, UniquePayButton, UniqueFooter } from './StyledComponents'; // Keep the existing imports
import { color } from '@mui/system';

const Payment = ({ upiID, amount, onPaymentComplete, onStep4 }) => {
  const [utrNumber, setUtrNumber] = useState('');
  const qrValue = `upi://pay?pa=${upiID}&am=${amount}`;

  const handlePayNow = () => {
    window.location.href = `upi://pay?pa=${upiID}&am=${amount}`;
    onPaymentComplete();
  };

  const handleCopyUPI = () => {
    navigator.clipboard.writeText(upiID).then(() => alert('UPI ID copied to clipboard!'));
  };

  const handleDownloadQR = () => {
    const canvas = document.querySelector('canvas');
    const imageURL = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = 'upi-qr-code.png';
    link.click();
  };

  const handleUTRChange = (event) => {
    setUtrNumber(event.target.value);
  };

  const handleConfirmPayment = () => {
    // Trigger Step 4, or whatever logic you have to move to the next step
    onPaymentComplete(); // Call the callback or method to move to step 4
  };

  return (
    <UniquePaymentContainer>
      <UniquePaymentHeader>Pay with UPI</UniquePaymentHeader>
      <UniqueQRWrapper>
        <QRCode value={qrValue} size={256} bgColor="transparent" fgColor="white" level="H" />
      </UniqueQRWrapper>
      
      {/* Display Amount Below QR Code */}
      <p style={{
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#fff',
        marginTop: '15px',
        textAlign: 'center',
        backgroundColor: '#333',
        padding: '10px',
        borderRadius: '5px',
      }}>
        Amount to be paid: ₹{amount}
      </p>

      <br />
      <UniquePayButton onClick={handlePayNow}>Pay Now with UPI App</UniquePayButton>
      <br />
      <IconContainer>
        <Icon onClick={handleCopyUPI}>
          <FaCopy size={18} /> {/* Copy icon */}
          Copy UPI
        </Icon>
        <Icon onClick={handleDownloadQR}>
          <FaDownload size={18} /> {/* Download icon */}
          Download QR
        </Icon>
      </IconContainer>
      

    </UniquePaymentContainer>
  );
};

export default Payment;
