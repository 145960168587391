import React, { useState, useEffect } from 'react';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import styled from 'styled-components';
import bgImage from '../../images/bg2.jpg'; // Ensure the path is correct
import Footer from '../../components/Footer';

const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-image: url(${bgImage}); /* Use imported image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 255, 0.25); /* Blue overlay with 25% opacity */
    z-index: 1;
  }
`;

const FormContainer = styled.div`
  position: relative;
  margin-top: 25vh;
  margin-bottom: 2rem;
  z-index: 2; /* Ensures content is above the overlay */
  width: 100%;
  max-width: 600px;
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const FooterWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
`;

const FormManager = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    paymentType: '',
    phoneNumber: '',
    amount: '',
    remarks: '',
  });

  // Scroll to top on step change
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [step]);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <BackgroundWrapper>
      <FormContainer>
        {step === 1 && (
          <Step1
            formData={formData}
            handleChange={handleChange}
            handleNextStep={handleNextStep}
          />
        )}
        {step === 2 && (
          <Step2
            formData={formData}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
          />
        )}
        {step === 3 && (
          <Step3
            formData={formData}
            handlePreviousStep={handlePreviousStep}
            setStep={setStep}
          />
        )}
        {step === 4 && <Step4 />}
      </FormContainer>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </BackgroundWrapper>
  );
};

export default FormManager;